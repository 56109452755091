<template>
  <div class="app-prematch">
    <!-- Prematch -->
    <div class="prematch-wrap">
      <!-- Leagues -->
      <div
        v-if="windowWidth >= 1080"
        class="leagues-nav"
      >
        <section>
          <div class="leagues-wrap">
            <b-list-group>
              <b-list-group-item
                class="league"
                :active="selectedLeague === 'all'"
                @click="handleLeagues('all')"
              >
                <span class="league-symbol">
                  <feather-icon
                    icon="LayersIcon"
                  />
                </span>
                <span class="league-name">
                  전체
                </span>
                <b-badge
                  variant="dark"
                  class="league-count"
                >
                  {{ totalLeagues }}
                </b-badge>
              </b-list-group-item>

              <template
                v-if="!isLoading"
              >
                <b-list-group-item
                  v-for="(league, lIndex) of leagues"
                  :key="lIndex"
                  class="league"
                  :active="selectedLeague === league.id"
                  @click="handleLeagues(league.id)"
                >
                  <span
                    class="league-symbol"
                  >
                    <b-img
                      :src="handleImageUrl(league.name)"
                      @error="handleImageError"
                    />
                  </span>
                  <span
                    class="league-name"
                    :title="league.nameCustom ? league.nameCustom : league.name"
                  >
                    {{ league.nameCustom ? league.nameCustom : league.name }}
                  </span>
                  <b-badge
                    variant="dark"
                    class="league-count"
                  >
                    {{ league.count }}
                  </b-badge>
                </b-list-group-item>
              </template>
              <template
                v-else
              >
                <b-list-group-item>
                  <div class="d-flex justify-content-center mt-3 mb-2">
                    <b-spinner
                      variant="primary"
                      label="Text Centered"
                    />
                  </div>
                </b-list-group-item>
              </template>
            </b-list-group>
          </div>
        </section>
      </div>

      <!-- Games -->
      <div
        class="games-main"
      >
        <!-- games-nav -->
        <section>
          <b-card>
            <div class="games-nav">
              <!-- sports -->
              <div
                v-if="false"
                class="games-sports"
              >

                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedSports"
                    v-ripple.400="'rgba(200, 191, 199, 0.15)'"
                    :options="filteredSports"
                    buttons
                    button-variant="primary"
                    class="full-width"
                    @change="handleRadioSports($event)"
                  />
                </b-form-group>
              </div>
              <div>
                <b-form-radio-group
                  v-model="selectedSports"
                  v-ripple.400="'rgba(200, 191, 199, 0.15)'"
                  buttons
                  button-variant="primary"
                  style="width: 100%"
                  class="d-flex flex-wrap"
                  @change="handleRadioSports"
                >
                  <b-form-radio
                    v-for="option in filteredSports"
                    :key="option.value"
                    :value="option.value"
                    class="text-nowrap"
                    style="width: 90px; padding: 0.786rem 0.9rem;"
                  >
                    <img
                      v-if="option.value === 'soccer'"
                      src="./icon-soccer.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'basketball'"
                      src="./icon-basketball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'baseball'"
                      src="./icon-baseball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'tennis'"
                      src="./icon-tennis.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'volleyball'"
                      src="./icon-volleyball.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'football'"
                      src="./icon-football.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'hockey'"
                      src="./icon-hockey.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >
                    <img
                      v-if="option.value === 'esports'"
                      src="./icon-esports.svg"
                      class="p-0"
                      style="max-height: 1em;"
                    >

                    <span
                      v-if="windowWidth >= 600"
                      class="text-nowrap"
                    > {{ option.text }}
                    </span>
                  </b-form-radio>
                </b-form-radio-group>

              </div>
              <!-- search -->
              <div class="games-search">
                <b-form-group label-for="searchTextInput">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="searchTextInput"
                      v-model="crossFilter.searchText"
                      type="text"
                      placeholder="리그명 & 팀명"
                      @keyup="handleKeyupSearchText()"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-card>
        </section>

        <!-- games-contents -->
        <section>
          <b-card>
            <template
              v-if="!isLoading"
            >
              <template
                v-if="paginatedItems.length > 0 "
              >
                <!-- bonus -->
                <div class="bonus-wrap">
                  <div class="btn-wrap">
                    <b-button
                      v-for="(event, eIndex) in filteredSetEvents"
                      :key="eIndex"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"

                      :class="{'cart-selected': filteredCartItems.eventItems.includes(event.eventName) }"
                      @click="addCart('Bonus', event, event.bonusOdds)"
                    >
                      <span>
                        {{ formatValue(event.eventName) }}
                      </span>
                      <span>
                        {{ event.bonusOdds }}
                      </span>
                    </b-button>
                  </div>
                </div>

                <!-- games -->
                <div class="games-wrap">
                  <div class="games">
                    <!-- games-->
                    <div
                      v-for="(game, gIndex) in paginatedItems"
                      :key="gIndex"
                      class="game"
                    >
                      <div
                        class="game-header"
                      >
                        <div class="league">
                          <template>
                            <span
                              class="league-symbol"
                            >
                              <b-img
                                :src="handleImageUrl(game.leagueName)"
                                @error="handleImageError"
                              />
                            </span>
                            <span class="league-name mr-1">{{ game.leagueNameCustom ? game.leagueNameCustom : game.leagueName }}</span>
                            <span class="float-right d-none text-info">
                              // {{ game.homeNameCustom || game.homeName }} vs {{ game.awayNameCustom || game.awayName }}
                            </span>
                            <span
                              v-if="windowWidth <= 768"
                              class="float-right"
                            >
                              <feather-icon
                                size="16"
                                icon="ClockIcon"
                              />
                              {{ game | formatGameTime }}
                            </span>
                          </template>
                        </div>
                      </div>
                      <div
                        class="game-contents"
                      >
                        <template v-if="windowWidth <= 768">
                          <div class="game-board">
                            <div
                              style="border-left: solid 1px #fff; padding-left: 0.5rem;"
                            >
                              <div>
                                <div class="home">
                                  <span class="text-left text-truncate team">{{ game.homeNameCustom || game.homeName }} </span>
                                </div>
                                <div class="away">
                                  <span class="text-right text-truncate team"> {{ game.awayNameCustom || game.awayName }}</span>
                                </div>
                              </div>
                            </div>
                            <template v-if="false">
                              <div
                                v-if="windowWidth <= 768"
                                class="game-time"
                              >
                                <span class="line" />
                                <span class="tiem mx-1">
                                  <feather-icon
                                    v-if="false"
                                    size="16"
                                    icon="ClockIcon"
                                  />
                                  {{ game.startTime }}
                                </span>
                                <span class="line" />
                              </div>
                              <div class="game-score">
                                <div class="home">
                                  <span class="text-left text-truncate team">{{ game.homeNameCustom || game.homeName }} </span>
                                  <span class="score">
                                    {{ game.score ? game.score.split(':')[0] : 0 }}
                                  </span>
                                </div>
                                <div class="away">
                                  <span class="score">
                                    {{ game.score ? game.score.split(':')[0] : 0 }}
                                  </span>
                                  <span class="text-right text-truncate team"> {{ game.awayNameCustom || game.awayName }}</span>
                                </div>
                              </div>
                            </template>
                          </div>
                          <hr
                            style="margin: 0.5rem 0"
                          >
                        </template>
                        <template
                          v-for="(odd, oIndex) in (game.odds)"
                        >
                          <!--                          v-if="filteredMarket(odd.id)"-->
                          <div
                            :key="oIndex"
                            class="odd-row"
                          >
                            <div class="game-info text-truncate">
                              <span
                                v-if="windowWidth > 768"
                                class="game-time"
                              >
                                <feather-icon
                                  size="16"
                                  icon="ClockIcon"
                                />
                                {{ formatDate(game.startDate) }} {{ game.startTime }}
                              </span>
                              <span
                                style="padding-right: 0.5rem"
                              >
                                {{ getOddName(odd.name) }}
                              </span>
                            </div>
                            <div class="game-part">
                              <div class="btn-wrap">
                                <template
                                  v-for="(part, pIndex) in odd.participants"
                                >
                                  <!-- HOME -->
                                  <div
                                    v-if="pIndex === 0"
                                    :key="`home-${pIndex}`"
                                    class="home"
                                  >
                                    <b-button
                                      variant="outline-secondary"
                                      class="d-flex align-items-center"
                                      :class="{ 'cart-selected': filteredCartItems.oddsItems.includes(part.id) }"
                                      @click="addCart(game, odd, part)"
                                    >
                                      <!-- d-flex align-items-center justify-content-between -->
                                      <span
                                        v-if="windowWidth > 600"
                                        class="text-truncate"
                                        :title="game.homeNameCustom || game.homeName"
                                      >
                                        {{ game.homeNameCustom || game.homeName }}
                                      </span>
                                      <span>
                                        <img
                                          v-if="odd.name.toLowerCase().includes('over/under')"
                                          src="./icon-over.svg"
                                          class="p-0"
                                          style="max-height: 1em;"
                                        >
                                        <img
                                          v-if="odd.name.toLowerCase().includes('handicap')"
                                          src="./icon-handicap.svg"
                                          class="p-0"
                                          style="max-height: 1em;"
                                        >
                                      </span>
                                      <span>{{ part.value_eu }}</span>
                                    </b-button>
                                  </div>

                                  <!-- VS -->
                                  <div
                                    v-if="odd.participants.length === 3 && pIndex === 1"
                                    :key="`middle-${pIndex}`"

                                    class="vs"
                                  >
                                    <b-button
                                      variant="outline-secondary"
                                      :class="{ 'cart-selected': filteredCartItems.oddsItems.includes(part.id) }"
                                      @click="addCart(game, odd, part)"
                                    >
                                      {{ part.value_eu }}
                                    </b-button>
                                  </div>
                                  <div
                                    v-else-if="odd.participants.length === 2 && pIndex === 0"
                                    :key="`middle-${pIndex}`"
                                    class="vs"
                                  >
                                    <b-button
                                      variant="outline-secondary"
                                    >
                                      {{ part.handicap ? `${part.handicap}` : 'VS' }}
                                    </b-button>
                                  </div>

                                  <!-- AWAY -->
                                  <div
                                    v-if="pIndex === odd.participants.length - 1"
                                    :key="`away-${pIndex}`"

                                    class="away"
                                  >
                                    <b-button
                                      variant="outline-secondary"
                                      class="d-flex align-items-center"
                                      :class="{ 'cart-selected': filteredCartItems.oddsItems.includes(part.id) }"
                                      @click="addCart(game, odd, part)"
                                    >
                                      <!-- d-flex align-items-center justify-content-between -->
                                      <span>{{ part.value_eu }}</span>
                                      <span>
                                        <img
                                          v-if="odd.name.toLowerCase().includes('over/under')"
                                          src="./icon-under.svg"
                                          class="p-0"
                                          style="max-height: 1em;"
                                        >
                                        <img
                                          v-if="odd.name.toLowerCase().includes('handicap')"
                                          src="./icon-handicap.svg"
                                          class="p-0"
                                          style="max-height: 1em;"
                                        >
                                      </span>
                                      <span
                                        v-if="windowWidth > 600"
                                        class="text-truncate"
                                        :title="game.awayNameCustom || game.awayName"
                                      >
                                        {{ game.awayNameCustom || game.awayName }}
                                      </span>
                                    </b-button>
                                  </div>
                                </template>
                              </div>
                            </div>

                          </div>
                        </template>
                      </div>
                    </div>
                    <!-- .games-->
                    <!-- pagination -->
                    <div
                      class="d-flex justify-content-center p-1"
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                    <!-- .Pagination -->
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="searchText.trim().length > 0"
                  class="search-result"
                >
                  "{{ searchText }}" 조회결과 {{ paginatedItems.length }}건
                </div>
              </template>
            </template>
            <template
              v-else
            >
              <div class="d-flex justify-content-center mt-3 mb-2">
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
            </template>
          </b-card>
        </section>
      </div>

      <!-- Cart -->
      <template
        v-if="windowWidth >= 1280"
      >
        <div
          class="cart-aside"
        >
          <section>
            <sports-cart
              :cart-items.sync="cartItems"
              :filtered-set-menu="filteredSetMenu"
              :is-loading="isLoading"
              :window-width="windowWidth"
              @remove-cart="removeCart"
              @reset-cart="resetCart"
              @create-bet="createBet"
            />
          </section>
        </div>
      </template>
      <template v-else>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-toggle.sidebar-right
          variant="primary"
          class="cart-btn btn-icon position-fixed"
          style="bottom: 5%; right: 75px; padding: 10px; overflow:visible;"
        >
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
          />
          <b-badge
            class="position-absolute text-bold badge-glow m-0"
            style="background-color: #0ba81c; right: -7px; top: -10px;"
            pill
          >
            {{ cartItems.length }}
          </b-badge>
          <span v-if="windowWidth > 600"> BET CART </span>
        </b-button>
        <b-sidebar
          id="sidebar-right"
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <sports-cart
            :cart-items.sync="cartItems"
            :filtered-set-menu="filteredSetMenu"
            :is-loading="isLoading"
            :window-width="windowWidth"
            @remove-cart="removeCart"
            @reset-cart="resetCart"
            @create-bet="createBet"
          />
        </b-sidebar>
      </template>
    </div>

    <!--  -->
    <div
      v-if="false"
      class="my-1"
    >
      <div
        v-if="false"
        class="mb-1"
      >
        currentURL: <b> {{ currentURL }} </b> <br>
        componentName: <b> {{ componentName }} </b> <br>
      </div>

      <div
        class="mb-1"
      >
        window width: <b> {{ windowWidth }} </b> px <br>
        prematc width: <b>{{ prematchWidth }} </b> px <br></br>
      </div>
      <div
        class="mb-1"
      >
        isMobile: <b> {{ isMobile }} </b> ( &lt;= 834 px)<br>
      </div>

      <div
        class="mb-1"
      >
        selectedSports: <b> {{ selectedSports }} </b> <br>
        isLoading: <b> {{ isLoading }} </b> <br>

        filteredSetMenu: <b> {{ filteredSetMenu.length }} </b> <br>
        filteredSports: <b> {{ filteredSports.length }} </b> <br>
        filteredSetEvents: <b> {{ filteredSetEvents.length }} </b> <br>
        totalLeagues: <b> {{ totalLeagues }} </b> <br>
        leagues: <b> {{ leagues.length }} </b> <br>
        games: <b> {{ games.length }} </b> <br>
        filteredItems: <b> {{ filteredItems.length }} </b> <br>
        filteredCartItems: <b> {{ filteredCartItems }} </b> <br>

        <!-- paginatedItems: <b> {{ paginatedItems }} </b> <br> -->
        pageFrom: <b> {{ pageFrom }} </b> <br>
        pageTo: <b> {{ pageTo }} </b> <br>
        pageOf: <b> {{ pageOf }} </b> <br>
        totalRows: <b> {{ totalRows }} </b> <br>
        filteredCollapsedGame: <b> {{ filteredCollapsedGame }} </b> <br>

        cartItems: <b> {{ cartItems }} </b> <br>
        <!-- userData: <b> {{ userData }} </b> <br> -->
        <!-- paginatedMeta: <b> {{ paginatedMeta }} </b> <br> -->

        deviceType:  <b>{{ deviceType }} </b> px <br>
        isMobile: {{ isMobile }}
        isTablet: {{ isTablet }}
        isDesktop: {{ isDesktop }}

        windowWidth: <b>{{ windowWidth }} </b> px <br>
        prematchWidth: <b>{{ prematchWidth }} </b> px <br>
        gamesMainWidth: <b>{{ gamesMainWidth }} </b> px <br>
        leaguesNavWidth: <b>{{ leaguesNavWidth }} </b> px <br>
        cartAsideWidth: <b>{{ cartAsideWidth }} </b> px <br>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BBadge,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BSidebar,
  VBToggle,
  BSpinner,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ITEM,
  FETCH_GAME_SET_ODDS_CUSTOM,
  FETCH_SET_EVENT,
  FETCH_GAME_SET_COMBINATION,
} from '@/store/settings/action'
import { CLEAR_CART_ITEM, REMOVE_CART_ITEM } from '@/store/cart/mutation'
import { ADD_CART, UPDATE_CART_ITEM } from '@/store/cart/action'
import { BET } from '@/store/bet/action'

import * as moment from 'moment-timezone'
// import { prematch } from '@/views/Sports-Games/Sports-Cross/fake-db'
import SportsCart from './Sports-Cart.vue'

moment().tz('Asia/Seoul')

const authStore = createNamespacedHelpers('authStore')
const settingsStore = createNamespacedHelpers('settingsStore')
const cartStore = createNamespacedHelpers('cartStore')
const crossStore = createNamespacedHelpers('crossStore')
const betStore = createNamespacedHelpers('betStore')

export default {
  name: 'SportsSpecial',

  components: {
    // BSV
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BCard,
    BListGroup,
    BBadge,
    BListGroupItem,
    BImg,
    BSidebar,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BSpinner,
    BPagination,

    // Child-Components
    SportsCart,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
    formatGameTime: value => {
      const dataFormat = 'YYYY-MM-DD HH:mm'
      if (!value.startDate || !value.startTime) return moment('9999-12-31 23:59:59').format(dataFormat)

      const dateTime = moment(`${value.startDate.replace(/\./g, '-')}T${value.startTime}`)
      return dateTime.format(dataFormat)
    },
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,
      deviceType: navigator.userAgent,

      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      isTablet: /iPad|Tablet|Nexus 7|Kindle|GT-P1000|SCH-I800|GT-I9100/i.test(navigator.userAgent),
      isDesktop: !this.isMobile && !this.isTablet,

      // Width
      windowWidth: 0,
      prematchWidth: 0,
      gamesMainWidth: 0,
      leaguesNavWidth: 0,
      leaguesNavFlex: '',
      cartAsideWidth: 0,
      cartAsideFlex: '',

      isLoading: false,

      // userData: JSON.parse(localStorage.getItem('userData')),
      userData: this.$store.getters['authStore/user'],

      // Games
      orgData: [],
      games: [],

      // Leagues
      selectedLeague: '',
      leagues: [],

      // Sports & Search
      selectedSports: '',
      searchText: '',

      collapsedGame: {},

      // paginatedItems
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 30, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0,
    }
  },
  computed: {
    ...settingsStore.mapGetters([
      'fetchSetEvent',
      'fetchGameSetMenu',
      'fetchGameSetItem',
      'fetchGameSetOddsCustom',
      'fetchGameSetCombination',
    ]),
    ...authStore.mapGetters([
      'user',
    ]),
    ...crossStore.mapGetters([
      'crossFilter',
    ]),
    ...cartStore.mapGetters([
      'cartItems',
    ]),

    formatDate() {
      return value => {
        if (!value) return
        const formatDate1 = 'YYYY-MM-DD'
        const formatDate2 = 'YY-MM-DD'
        const formatDate3 = 'MM-DD'
        if (this.gamesMainWidth >= 670) return moment(value).format(formatDate3)
        if (this.gamesMainWidth >= 620) return moment(value).format(formatDate3)
        if (this.gamesMainWidth < 620) return moment(value).format(formatDate3)
        // return moment(value).format('YYYY.MM.DD HH:mm')
      }
    },
    formatValue() {
      return value => {
        // console.log(value)
        if (!value) return
        if (this.windowWidth < 400) return `${value.slice(0, 3)}`
        if (this.windowWidth < 500) return `${value.slice(0, 6)}`
        return value
      }
    },
    totalLeagues() {
      return this.leagues.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0) || 0
    },
    getOddName() {
      return value => {
        const termMapping = {
          'Match Winner': '승무패',
          'Home/Away': '승무패',

          'Goals Over/Under': '오버언더',
          'Over/Under': '오버언더',
          'Over/Under by Games in Match': '오버언더',
          'Total Maps': '오버언더',

          'Asian Handicap': '핸디캡',
          'Asian Handicap (Games)': '핸디캡',
          'Maps Handicap': '핸디캡',

          'Asian Handicap 1st Qtr': '핸디캡 1쿼터',
          'Over/Under 1st Qtr': '오버언더 1쿼터',

          'A Run (1st Inning)': '1이닝 득/무득',
          'Asian Handicap (4.5 Innings)': '핸디캡 4.5이닝',
          'Asian Handicap (1st 5 Innings)': '핸디캡 5이닝',
          'Over/Under (4.5 Innings)': '오버언더 4.5이닝',
          'Over/Under (1st 5 Innings)': '오버언더 5이닝',
          'Odd/Even (Including OT)': '홀수/짝수',

          'Asian Handicap (1st Set))': '핸디캡 1세트',
          'Over/Under (1st Set)': '오버언더 1세트',

          'Asian Handicap (1st Period)': '핸디캡 1피리어드',
          'Over/Under (1st Period)': '오버언더 1피리어드',
        }
        return termMapping[value] || value
      }
    },
    filteredSetMenu() {
      const result = this.fetchGameSetMenu.filter(item => item.useYn === 'Y' && item.name === 'Sports-Special')
      return result.length > 0 ? result : [{}]
    },
    filteredSports() {
      const result = this.fetchGameSetItem
        .filter(item => item.useYn === 'Y' && item.menuName === 'Sports-Special' && item.site === this.$site)
        .map(item => ({ text: item.nameCustom, value: item.name }))
      return result.length > 0 ? result : [{}]
    },
    filteredSetEvents() {
      const result = this.fetchSetEvent.filter(item => item.active === 'true' && item.gameName === 'Sports-Special')
      return result.length > 0 ? result : [{}]
    },
    filteredCartOddsItems() {
      return this.cartItems.map(item => item.oddsId)
    },
    filteredCartEventItems() {
      return this.cartItems
        .filter(item => item.homeAway === 'Bonus')
        .map(item => item.leagueName)
    },
    filteredCartItems() {
      // oddsId만 추출
      const oddsItems = this.cartItems.map(item => item.oddsId)

      // homeAway가 'Bonus'인 경우의 leagueName 추출
      const eventItems = this.cartItems
        .filter(item => item.homeAway === 'Bonus')
        .map(item => item.leagueName)

      return {
        oddsItems,
        eventItems,
      }
    },
    filteredCollapsedGame() {
      return JSON.stringify(this.collapsedGame)
    },
    // filtered Items
    filteredItems() {
      let filteredItems = this.games || []

      // 선택된 리그에 해당하는 경기만 필터링합니다.
      if (this.selectedLeague !== 'all') {
        filteredItems = filteredItems.filter(item => item.leagueId === this.selectedLeague)
      }

      // 검색어로 필터링합니다.
      const searchText = this.searchText.toLowerCase().trim()
      if (searchText) {
        const lowerCasedSearchText = searchText.toLowerCase()
        filteredItems = filteredItems.filter(item => item.leagueName.toLowerCase().includes(lowerCasedSearchText)
            || item.homeName.toLowerCase().includes(lowerCasedSearchText)
            || item.awayName.toLowerCase().includes(lowerCasedSearchText))
      }

      return filteredItems
    },
    // filtered Items => Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },

    // filtered Items => Pagination Meta
    paginatedMeta() {
      const totalRows = this.filteredItems.length || 0
      const sIdx = (this.currentPage - 1) * this.perPage
      const eIdx = Math.min(sIdx + this.perPage, totalRows)

      return {
        from: sIdx + (totalRows ? 1 : 0),
        to: sIdx + totalRows,
        of: totalRows,
        sIdx,
        eIdx,
        rows: this.filteredItems.slice(sIdx, eIdx),
      }
    },
  },

  created() {
    // 인스턴스가 생성된 직후
    // console.log(this.componentName, 'create()')
  },

  beforeMount() {
    // 컴포넌트가 마운트되기 직전
    // console.log(this.componentName, 'beforeMount()')
  },

  mounted() {
    // 컴포넌트가 마운트된 직후
    // console.log(this.componentName, 'mounted()')

    // Resize는 Mounted해야함
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.init()
  },

  beforeUpdate() {
    // 데이터가 변하여 업데이트가 발생하기 직전
    // console.log(this.componentName, 'beforeUpdate()')
  },

  updated() {
    // 데이터가 변하여 업데이트가 발생한 직후
    // console.log(this.componentName, 'updated()')
  },

  beforeDestroy() {
    // 컴포넌트가 파괴되기 직전
    // console.log(this.componentName, 'beforeDestroy()')
    window.removeEventListener('resize', this.onResize)
    const site = this.$site
    this.$socket.off(`getSpecialUpdate:${site}`)
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: this.userData.userid,
      menu: '',
      menuCategory: '',
    })
  },

  methods: {
    // Setting
    ...settingsStore.mapActions({
      $fetchSetEvent: FETCH_SET_EVENT,
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsCustom: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetCombination: FETCH_GAME_SET_COMBINATION,
    }),

    // Cart
    ...cartStore.mapMutations({
      clearCart: CLEAR_CART_ITEM,
    }),
    ...cartStore.mapActions({
      addToCart: ADD_CART,
      updateCart: UPDATE_CART_ITEM,
      removeCartItem: REMOVE_CART_ITEM,
    }),

    // Bet
    ...betStore.mapActions({
      createBetHistory: BET,
    }),

    // Settings
    onResize() {
      this.windowWidth = window.innerWidth
      this.prematchWidth = (document.querySelector('.prematch-wrap') || { offsetWidth: 0 }).offsetWidth
      this.gamesMainWidth = (document.querySelector('.games-main') || { offsetWidth: 0 }).offsetWidth
      this.leaguesNavWidth = (document.querySelector('.leagues-nav') || { offsetWidth: 0 }).offsetWidth
      this.cartAsideWidth = (document.querySelector('.cart-aside') || { offsetWidth: 0 }).offsetWidth

      // if (this.windowWidth < 1250) {
      //   this.leaguesNavFlex = 'flex: 0 0 270px; width: 270px; max-width: 270px'
      //   this.cartAsideFlex = 'flex: 0 0 270px; width: 270px; max-width: 270px'
      // } else {
      //   this.leaguesNavFlex = 'flex: 0 0 285px; width: 285px; max-width: 285px'
      //   this.cartAsideFlex = 'flex: 0 0 285px; width: 285px; max-width: 285px'
      // }
    },

    init() {
      // console.log(this.componentName, 'init()')
      console.log(`Device type: ${navigator.userAgent}`)

      this.deviceType = navigator.userAgent

      this.selectedLeague = 'all'
      this.selectedSports = 'soccer'
      this.isLoading = true

      this.setMenu()
      this.fetchData()
      this.resetCart()

      // 사이트별 이벤트 이름으로 소켓 데이터 수신
      const site = this.$site
      this.$socket.on(`getSpecialUpdate:${site}`, data => {
        try {
          if (data) {
            this.orgData = JSON.parse(data)
            this.fetchGames()
            this.updateCardItems()

            console.log(`${this.orgData[1].length} 업데이트됨`)
          } else {
            console.error('데이터를 받지 못했습니다')
          }
        } catch (error) {
          console.error('데이터 파싱 오류: ', error)
        }
      })
    },

    async setMenu() {
      console.log(this.componentName, 'setMenu()')
      await this.$fetchGameSetItem({
        site: this.$site,
      })
      await this.$fetchSetEvent({
        site: this.$site,
      })
      await this.$fetchGameSetCombination({
        site: this.$site,
        menuName: 'Sports-Special',
        sports: this.selectedSports,
      })
    },

    async fetchData() {
      await this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: this.userData.userid,
        menu: 'Sports-Special',
        menuCategory: this.selectedSports,
      })
      await this.$socket.emit('getSpecialFirst', {
        site: this.$site,
        userid: this.userData.userid,
        sports: this.selectedSports,
      }, data => {
        try {
          if (data && typeof data === 'string' && data.trim() !== '') { // data가 유효한 문자열인지 확인
            this.orgData = JSON.parse(data)

            if (Array.isArray(this.orgData) && this.orgData.length > 1) { // orgData가 예상한 형식인지 확인
              this.fetchGames()

              console.log('leagues: ', this.orgData[0])
              console.log('games: ', this.orgData[1])
              console.log(`${this.orgData[1].length} 업데이트됨`)
            } else {
              console.warn('데이터가 예상한 형식이 아닙니다')
            }
          } else {
            console.warn('데이터를 받지 못했습니다')
          }
        } catch (error) {
          console.error('데이터 파싱 오류: ', error)
        }
      })
    },

    fetchGames() {
      // 배열 해체 (Array Destructuring)
      [this.leagues, this.games] = this.orgData
      this.isLoading = false
    },

    // Handle
    handleImageUrl(value) {
      const imageMapping = {
        soccer: item => item.split(':')[0].trim(),
        basketball: item => item.split(':')[0].trim(),
        baseball: () => 'default', // item.split(':')[0].trim(),
        tennis: () => 'default', // item.match(/\(([^)]+)\)/)[1],
        volleyball: () => 'default',
        football: () => 'default',
        hockey: () => 'default',
        esports: item => item.split(' ')[0].trim(),
        default: () => 'default',
      }
      const selectedSportHandler = imageMapping[this.selectedSports] || imageMapping.default
      const imageName = selectedSportHandler(value || 'default')

      const url = `https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/${imageName}.svg`
      return url
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
    handleLeagues(leagueId) {
      this.selectedLeague = leagueId
    },

    handleRadioSports(value) {
      console.log('sports:::', value)
      this.orgData = []
      this.games = []
      this.leagues = []
      this.searchText = ''
      this.currentPage = 1
      this.crossFilter.searchText = ''
      this.selectedSports = value
      this.fetchData()
    },
    handleKeyupSearchText() {
      this.searchText = document.querySelector('#searchTextInput').value
    },

    // 게임조합 유효성 검사
    validCombination(gameRow, odd) {
      // updatedItems 생성
      const updatedItems = Object.entries(
        [
          ...this.cartItems,
          {
            gameId: gameRow.eventId, // gameRow의 eventId 사용
            gameTypeId: odd.id, // odd의 id를 gameTypeId로 사용
          },
        ].reduce((acc, { gameId, gameTypeId }) => {
          if (!acc[gameId]) {
            acc[gameId] = new Set()
          }
          acc[gameId].add(Number(gameTypeId))
          return acc
        }, {}),
      ).map(([gameId, gameTypeIds]) => ({
        gameId,
        gameTypeIds: Array.from(gameTypeIds).sort((a, b) => a - b),
      }))

      console.log('Updated Items:', updatedItems)

      // 조합 확인
      const hasInvalidCombination = updatedItems.some(({ gameId, gameTypeIds }) => this.fetchGameSetCombination.some(({ combination, alertMessage, allow }) => {
        const combinationArray = combination.split(',').map(Number) // 조합을 숫자 배열로 변환
        console.log('combinationArray:', combinationArray)

        if (allow === 1) {
        // 전체 조합 확인
          const allGameTypeIds = updatedItems.flatMap(item => item.gameTypeIds)
          console.log('1. allGameTypeIds:', allGameTypeIds)
          const isCombinationMatched = combinationArray.every(id => allGameTypeIds.includes(id))
          if (isCombinationMatched) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${alertMessage}`,
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            }, {
              position: 'top-center', // 가운데 위치
            })
            return true // 조건 충족 시 true 반환
          }
        }

        if (allow === 2) {
        // 동일 경기 내 조합 확인
          const isCombinationMatched = combinationArray.every(id => gameTypeIds.includes(id))
          console.log('2. gameTypeIds:', gameTypeIds)
          if (isCombinationMatched) {
            // alert(`Game ID ${gameId}: ${alertMessage}`)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${alertMessage}`,
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },

            }, {
              position: 'top-center', // 가운데 위치
            })
            return true // 조건 충족 시 true 반환
          }
        }

        return false // 조건 미충족 시 false 반환
      }))

      return hasInvalidCombination // 조합 위반 여부 반환
    },

    /* Cart Methods */
    addCart(gameRow, odd, participant) {
      // 게임조합 유효성 검사 실행
      const hasInvalidCombination = this.validCombination(gameRow, odd)

      if (hasInvalidCombination) {
        // 조건 충족 시 함수 중단
        return
      }

      let item = {}
      // 날짜와 시간이 없을 경우 현재 시간을 사용
      const gameDate = gameRow.startDate && gameRow.startTime ? `${gameRow.startDate} ${gameRow.startTime}` : moment().format('YYYY.MM.DD HH:mm')

      if (gameRow === 'Bonus') {
        const bId = `B00${Date.now()}`

        // console.log('gameRow ::', gameRow)
        // console.log('odd ::', odd)
        // console.log('participant ::', participant)
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Special',
          sports: this.selectedSports,
          gameId: bId, // eventId
          gameDate,
          leagueName: odd.eventName, // 보너스 이름
          homeName: '', // homeName
          awayName: '', // awayName
          gameTypeId: '',
          gameType: gameRow, // gameType
          oddsHome: 0,
          oddsDraw: 0,
          oddsAway: 0,
          oddsId: bId, // oddsId
          odds: participant, // bonus odds
          name: odd.minFolds.toString(), // options handicap
          homeAway: gameRow, // choose bonus
        }
      } else if (odd.participants.length > 2) {
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Special',
          sports: this.selectedSports,
          gameId: gameRow.eventId, // eventId
          gameDate,
          leagueName: gameRow.leagueName, // leagueName
          homeName: gameRow.homeName, // homeName
          awayName: gameRow.awayName, // awayName
          gameTypeId: odd.id,
          gameType: odd.name, // gameType
          oddsHome: odd.participants[0].value_eu,
          oddsDraw: odd.participants[1].value_eu,
          oddsAway: odd.participants[2].value_eu,
          oddsId: participant.id, // oddsId
          odds: participant.value_eu, // odds
          name: participant.handicap, // options handicap
          homeAway: participant.name, // choose home,draw,away
        }
      } else {
        item = {
          site: this.$site,
          userid: this.userData.userid,
          nickname: this.userData.nickname,
          category: 'Sports-Special',
          sports: this.selectedSports,
          gameId: gameRow.eventId, // eventId
          gameDate,
          leagueName: gameRow.leagueName, // leagueName
          homeName: gameRow.homeName, // homeName
          awayName: gameRow.awayName, // awayName
          gameTypeId: odd.id,
          gameType: odd.name, // gameType
          oddsHome: odd.participants[0].value_eu,
          // oddsDraw: odd.participants[0].handicap,
          oddsAway: odd.participants[1].value_eu,
          oddsId: participant.id, // oddsId
          odds: participant.value_eu, // odds
          name: participant.handicap, // options handicap
          homeAway: participant.name, // choose home,draw,away
        }
      }
      this.addToCart(item)
    },
    removeCart(item) {
      this.removeCartItem(item)
    },
    resetCart() {
      this.clearCart()
    },
    createBet(item) {
      console.log('createBet', item)
      this.createBetHistory(item)
    },
    updateCardItems() {
      this.updateCart(this.games)
    },
    // filteredMarket(id) {
    //   return !['1','2','3','4','5','6','7','8','9'].includes(id)
    // },
  },
}

// [method] Collapse 접기/펼치기(임시)
// handleCollapsedGame(game) {
//   const { sport, eventId } = game
//   // 키가 존재하지 않으면 빈 배열로 초기화
//   if (!this.collapsedGame[sport]) {
//     this.collapsedGame[sport] = []
//   }
//   const index = this.collapsedGame[sport].indexOf(eventId)
//   if (index !== -1) {
//     // 존재한다면, splice 함수를 사용하여 해당 요소를 삭제
//     this.collapsedGame[sport].splice(index, 1)
//     if (this.collapsedGame[sport].length === 0) {
//       delete this.collapsedGame[eventId]
//     }
//   } else {
//     this.collapsedGame[sport].push(eventId) // 추가
//   }
// },
// [computed] Collapse 접기/펼치기(임시)
// isCollapsedGame() {
//   return game => {
//     const { sport, eventId } = game
//     if (this.collapsedGame[sport]) {
//       return this.collapsedGame[sport].indexOf(eventId) !== -1
//     }
//     return false
//   }
// },
</script>
<style scoped>
/* app-prematch > common setting */
.app-prematch1 {
  background: lightblue;
}
.app-prematch * {
  color: white;
  font-size: 0.9rem;
}
.app-prematch .card {
  margin-bottom: 5px;
}
.app-prematch .card-body {
  padding: 0.2rem;
}

/* app-prematch > grid setting */
.prematch-wrap {
  display: flex;
}
.prematch-wrap .leagues-nav,
.prematch-wrap .cart-aside {
  flex: 0 0 285px; /* 고정된 너비 */
  width: 285px;
  max-width: 285px !important;
}
.prematch-wrap .games-main {
  flex: 1;
}
.app-prematch .leagues-nav section {
  margin-right: 5px;
}
.app-prematch .cart-aside section {
  margin-left: 5px;
}

/* Leagues-Nav */
.leagues-nav .league {
  display: flex;
  align-items: center;
}
.leagues-nav .league .league-name {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.leagues-nav .league .league-symbol svg,
.leagues-nav .league .league-symbol img {
  height: 15px;
  width: 20px;
  margin-right: 5px;
}
.leagues-nav .league .league-count {
  margin-left: auto;
  width: 35px;
}

/* games-nav */
.games-nav > div {
  padding: 15px;
}

.search-result {
  padding: 15px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 500; /* 글자를 두껍게 설정 */
}

/* event-wrap */
.bonus-wrap {
  padding-top:5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;

  margin-top: 5px;
  margin-bottom: 5px;
}
.bonus-wrap .btn-wrap {
  display: flex;
  gap: 0.7px;
}
.bonus-wrap .btn-wrap button {
  flex: 1;
  /* white-space: nowrap; */
}

/* games-wrap */
.games-wrap {
  padding-left: 15px;
  padding-right: 15px;
}

.games .game {
  margin-bottom: 0.5rem;
}

/* games */
.games .game .game-header {
  position: relative;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
  background-color: #161d31;
  border: 1px solid rgba(160, 160, 160, 0.2);
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}
.games .game .game-header .league-symbol svg,
.games .game .game-header .league-symbol img {
  height: 15px;
  width: 20px;
  margin-right: 5px;
}

.games .game .game-contents {
  background-color: #283046;
  border-radius: 0.428rem;
  padding: calc(20px + 15px) 7px 7px 7px ; /* header-contents 겹치게 */
  margin-top: -20px;
  box-shadow: 1px 1px 8px rgba(0,0,0,.3);
}

/* games > game-board */
.game-board .game-time {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 2px;
}
.game-board .game-time .line {
  height: 1px;
  width: 10%;
  background: #ccc;
}
.game-board .game-score {
  height: 30px;
  display: flex;
  width: 100%;
}
.game-board .game-score .home,
.game-board .game-score .away {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1b243e;
}
.game-board .game-score .home {
  padding-left: 1rem;
  padding-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-right: 1.5px;
}
.game-board .game-score .away {
  padding-right: 1rem;
  padding-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: 1.5px;
}
.game-board .game-score .home span.team,
.game-board .game-score .away span.team {
  flex: 0 0 70%;
  max-width: 70%;
}
.game-board .game-score .home span.score,
.game-board .game-score .away span.score  {
  /* padding: 5px 10px; */
  flex: 0 0 30%;
  max-width: 30%;
  text-align: center;
  background-color: #42414b;
}
.game-board .game-score .home span.score {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.game-board .game-score .away span.score {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* games > game-odd */
.game-contents .odd-row {
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.game-contents .odd-row .game-info {
  flex: 3;
  display: flex;
  justify-content: space-between;
}
.game-contents .odd-row .game-part {
  flex: 7;
}
.game-contents .odd-row .game-part .btn-wrap {
  display: flex;
  flex-wrap: wrap
}
.game-contents .odd-row .game-part .btn-wrap button {
  width: 100%;
  padding: 0.6rem 0.786rem ;
  border-radius: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.game-contents .odd-row .game-part .btn-wrap div {
  padding: 0.7px;
}
.game-contents .odd-row .game-part .btn-wrap div.home,
.game-contents .odd-row .game-part .btn-wrap div.away {
  /* flex: 4; 첫 번째 버튼 */
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.game-contents .odd-row .game-part .btn-wrap div.vs {
  /* flex: 2; 두 번째 버튼 */
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.game-contents .odd-row .game-part .btn-wrap div.home button > span:nth-child(1) {
  text-align: left;
  flex: 0 0 60%;
  max-width: 60%;
}
.game-contents .odd-row .game-part .btn-wrap div.home button > span:nth-child(2),
.game-contents .odd-row .game-part .btn-wrap div.home button > span:nth-child(3) {
  text-align: right;
  flex: 0 0 20%;
  max-width: 20%;
}
.game-contents .odd-row .game-part .btn-wrap div.away button > span:nth-child(1),
.game-contents .odd-row .game-part .btn-wrap div.away button > span:nth-child(2) {
  text-align: left;
  flex: 0 0 20%;
  max-width: 20%;
}
.game-contents .odd-row .game-part .btn-wrap div.away button > span:nth-child(3) {
  text-align: right;
  flex: 0 0 60%;
  max-width: 60%;
}

/* 버튼 선택 애니메이션 효과 정의 */
.btn-wrap .cart-selected {
  color: white !important;
  background: linear-gradient(to right, #bf53b1, #d369a4, #ff7c6e, #bf53b1);
  background-size: 400% 100% !important; /* 배경 크기를 세 배로 설정 */
  animation: gradient-slide 5s linear 1 forwards !important; /* 애니메이션 속성 적용 */
}
@keyframes gradient-slide {
  0% { background-position: 0 50%; }
  100% { background-position: 100% 50%; }
}

@media screen and (max-width: 768px) {
  .game-contents .odd-row .game-info {
    flex: 0 0 15%;
    max-width: 15%;
    display: flex;
    justify-content: flex-end;
  }
  .game-contents .odd-row .game-part {
    flex: 0 0 85%;
    max-width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .bonus-wrap .btn-wrap {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
  .bonus-wrap .btn-wrap button {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-around;
    gap: 3px;
    padding: 0.7rem 0.5rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .games-nav > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .games-nav .btn-group-toggle {
    gap: 2px;
  }
  .games-nav .btn-group > .btn {
    border-radius: 0.358rem !important;
  }
  .games-nav .form-group {
    margin-bottom: 0px;
  }

  .games .game .game-contents {
    padding: calc(10px + 5px) 7px 7px 7px;
    margin-top: -10px;
  }
  .game-contents .odd-row .game-info {
    flex: 0 0 20%;
    max-width: 20%;
    justify-content: flex-start;
  }
  .game-contents .odd-row .game-part {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .game-contents .odd-row .game-part .btn-wrap div.home,
  .game-contents .odd-row .game-part .btn-wrap div.away {
    flex: 0 0 36.666667%;
    max-width: 36.666667%;
  }
  .game-contents .odd-row .game-part .btn-wrap div.vs {
    flex: 0 0 26.666667%;
    max-width: 26.666667%;
  }
  .game-contents .odd-row .game-part .btn-wrap button {
    padding: 0.3rem 0.3rem;
  }
  .game-contents .odd-row .game-part .btn-wrap div.home button,
  .game-contents .odd-row .game-part .btn-wrap div.away button  {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .game-contents .odd-row .game-part .btn-wrap div.home button > span:nth-child(1) {
    text-align: right;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
  }
  .game-contents .odd-row .game-part .btn-wrap div.home button > span:nth-child(2) {
    text-align: left;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 5px;
  }
  .game-contents .odd-row .game-part .btn-wrap div.away button > span:nth-child(1) {
    text-align: right;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 5px;
  }
  .game-contents .odd-row .game-part .btn-wrap div.away button > span:nth-child(2) {
    text-align: left;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 5px;
  }

}
</style>
